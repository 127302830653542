import { HashRouter, Link, Route, Routes } from 'react-router-dom';

import { Home } from './pages/Home';
import { Page1 } from './pages/Page1';
import { Page2 } from './pages/Page2';
import { Page3 } from './pages/Page3';
import { Page4 } from './pages/Page4';
import { Page5 } from './pages/Page5';
import { Page6 } from './pages/Page6';
import { Page7 } from './pages/Page7';
import { Page8 } from './pages/Page8';
import React from 'react';

const App = () => (
  <HashRouter >
    <div className='nav'>
      <Link to="/">Home</Link>
      <Link to="/page1">Page1</Link>
      <Link to="/page2">Page2</Link>
      <Link to="/page3">Page3</Link>
      <Link to="/page4">Page4</Link>
      <Link to="/page5">Page5</Link>
      <Link to="/page6">Page6</Link>
      <Link to="/page7">Page7</Link>
      <Link to="/page8">Page8</Link>
    </div>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/page1" element={<Page1/>} />
      <Route path="/page2" element={<Page2/>} />
      <Route path="/page3" element={<Page3/>} />
      <Route path="/page4" element={<Page4/>} />
      <Route path="/page5" element={<Page5/>} />
      <Route path="/page6" element={<Page6/>} />
      <Route path="/page7" element={<Page7/>} />
      <Route path="/page8" element={<Page8/>} />
    </Routes>
  </HashRouter>
);

export default App;