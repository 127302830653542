import React from 'react';
import { useSearchParams } from 'react-router-dom';
export function Page7() {
  const [searchParams, setSearchParams] = useSearchParams();
  const onChangeText = (e) => {
    if (e.target.value) {
      searchParams.set('text', e.target.value)
    } else {
      searchParams.delete("text");
    }
    setSearchParams(searchParams);
  }
  const [showAnswer, setShowAnswer] = React.useState(false);
  return (
    <>
      <section className="goal">
        <h2>URLの操作</h2>
        <div className="wrap">
          <input value={searchParams.get("text") ?? ""} onChange={onChangeText} />
          <pre>{JSON.stringify(searchParams.get("text") ?? "", null, 2)}</pre>
        </div>
      </section>
      <hr className="border" />
      <p className="border_txt">これ以下は模写不要</p>
      <section className="question">
        <h2>問題7</h2>
        <div className="wrap">
          <p className="question_txt">
            以上のアプリケーションを作成しなさい<br />
            <br />
            【注意】<br />
            {`import { useSearchParams } from 'react-router-dom';`} が必要<br />
            URLにテキストが保存されている点に注意しなさい<br />
            リロードしてもデータがクリアされない点に注意しなさい<br />
          </p>
        </div>
      </section>
      <section className="answer">
        <h2>回答</h2>
        {showAnswer && <>
          <button className="answer_btn" onClick={() => {
            setShowAnswer(false)
          }}>回答を非表示</button>
          <div className="wrap">
            <pre>
              {`
import React from 'react';
import { useSearchParams } from 'react-router-dom';
export function Page7() {
  const [searchParams, setSearchParams] = useSearchParams();
  const onChangeText = (e) => {
    if (e.target.value) {
      searchParams.set('text', e.target.value )
    } else {
      searchParams.delete("text");
    }
    setSearchParams(searchParams);
  }
  return (
    <>
      <input value={searchParams.get("text") ?? ""} onChange={onChangeText} />
      <pre>{JSON.stringify(searchParams.get("text") ?? "", null, 2)}</pre>
    </>
  );
}
`}
            </pre>
          </div>
        </>}
        {!showAnswer && <>
          <button className="answer_btn" onClick={() => {
            setShowAnswer(true)
          }}>回答を表示</button>
        </>}
      </section>
    </>
  );
}