import React from 'react';
export function Page6() {
  const [text, setText] = React.useState(localStorage.getItem("text") ?? "");
  const onChangeText = (e) => {
    setText(e.target.value)
    localStorage.setItem("text", e.target.value);
  }
  const clearStorage = () => {
    localStorage.removeItem("text");
  }
  const [showAnswer, setShowAnswer] = React.useState(false);
  return (
    <>
      <section className="goal">
        <h2>ローカルストレージの操作</h2>
        <div className="wrap">
          <input value={text} onChange={onChangeText} />
          <button onClick={clearStorage}>ローカルストレージをクリア</button>
          <pre>{JSON.stringify(text, null, 2)}</pre>
        </div>
      </section>
      <hr className="border" />
      <p className="border_txt">これ以下は模写不要</p>
      <section className="question">
        <h2>問題6</h2>
        <div className="wrap">
          <p className="question_txt">
            以上のアプリケーションを作成しなさい<br />
            <br />
            【注意】<br />
            リロードしてもデータがクリアされない点に注意しなさい<br />
          </p>
        </div>
      </section>
      <section className="answer">
        <h2>回答</h2>
        {showAnswer && <>
          <button className="answer_btn" onClick={() => {
            setShowAnswer(false)
          }}>回答を非表示</button>
          <div className="wrap">
            <pre>
              {`
import React from 'react';
export function Page6() {
  const [text, setText] = React.useState(localStorage.getItem("text") ?? "");
  const onChangeText = (e) => {
    setText(e.target.value)
    localStorage.setItem("text", e.target.value);
  }
  const clearStorage = () => {
    localStorage.removeItem("text");
  }
  return (
    <>
      <input value={text} onChange={onChangeText} />
      <button onClick={clearStorage}>ローカルストレージをクリア</button>
      <pre>{JSON.stringify(text, null, 2)}</pre>
    </>
  );
}
`}
            </pre>
          </div>
        </>}
        {!showAnswer && <>
          <button className="answer_btn" onClick={() => {
            setShowAnswer(true)
          }}>回答を表示</button>
        </>}
      </section>
    </>
  );
}