import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import LoadingButton from "@mui/lab/LoadingButton";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export function Page8() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [showAnswer, setShowAnswer] = React.useState(false);
  return (
    <>
      <section className="goal">
        <h2>マテリアルUI</h2>
        <div className="wrap">
          <section>
            <Box sx={{ margin: "80px 0" }}>
              <CircularProgress color="success" />
            </Box>
          </section>
          <section>
            <Box sx={{ margin: "80px 0" }}>
              <Pagination page={page} onChange={(e, page) => {
                alert(page + 'ページがクリックされました');
                setPage(page);
              }} count={20} variant="outlined" shape="rounded" />
              <Button onClick={() => { setPage(1) }}><DirectionsRunIcon />1ページへもどる</Button>
            </Box>
          </section>
          <section>
            <Box sx={{ margin: "80px 0" }}>
              <LoadingButton
                onClick={() => {
                  setIsLoading(true)
                  setTimeout(() => {
                    setIsLoading(false)
                  }, 1000)
                }}
                loading={isLoading}>
                送信ボタン
              </LoadingButton>
            </Box>
          </section>
          <section>
            <Box sx={{ margin: "80px 0" }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>名前</TableCell>
                      <TableCell>趣味</TableCell>
                      <TableCell>特技</TableCell>
                      <TableCell>将来の夢</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>織田信長</TableCell>
                      <TableCell>野球</TableCell>
                      <TableCell>スライダー</TableCell>
                      <TableCell>野球選手</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>豊臣秀吉</TableCell>
                      <TableCell>サッカー</TableCell>
                      <TableCell>リフティング</TableCell>
                      <TableCell>野球選手</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>徳川家康</TableCell>
                      <TableCell>バスケ</TableCell>
                      <TableCell>アリウープ</TableCell>
                      <TableCell>エンジニア</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </section>
        </div>
      </section>
      <hr className="border" />
      <p className="border_txt">これ以下は模写不要</p>
      <section className="question">
        <h2>問題8</h2>
        <div className="wrap">
          <p className="question_txt">
            以上のアプリケーションを作成しなさい<br />
            <br />
            【参考】<br />
            <a href='https://mui.com/'>https://mui.com/</a><br />
            <a href='https://mui.com/material-ui/material-icons/?query=run'>https://mui.com/material-ui/material-icons/?query=run</a><br />
            <br />
            【注意】<br />
            yarn  add @mui/material @mui/icons-material @mui/lab @emotion/react @emotion/styled
            が必要<br />
          </p>
        </div>
      </section>
      <section className="answer">
        <h2>回答</h2>
        {showAnswer && <>
          <button className="answer_btn" onClick={() => {
            setShowAnswer(false)
          }}>回答を非表示</button>
          <div className="wrap">
            <pre>
              {`
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import LoadingButton from "@mui/lab/LoadingButton";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export function Page8() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  return (
    <>
      <section>
        <Box sx={{ margin: "80px 0" }}>
          <CircularProgress color="success" />
        </Box>
      </section>
      <section>
        <Box sx={{ margin: "80px 0" }}>
          <Pagination page={page} onChange={(e, page) => {
            alert(page + 'ページがクリックされました');
            setPage(page);
          }} count={20} variant="outlined" shape="rounded" />
          <Button onClick={() => { setPage(1) }}><DirectionsRunIcon />1ページへもどる</Button>
        </Box>
      </section>
      <section>
        <Box sx={{ margin: "80px 0" }}>
          <LoadingButton
            onClick={() => {
              setIsLoading(true)
              setTimeout(() => {
                setIsLoading(false)
              },1000)
            }}
            loading={isLoading}>
            送信ボタン
          </LoadingButton>
        </Box>
      </section>
      <section>
        <Box sx={{ margin: "80px 0" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>名前</TableCell>
                  <TableCell>趣味</TableCell>
                  <TableCell>特技</TableCell>
                  <TableCell>将来の夢</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>織田信長</TableCell>
                  <TableCell>野球</TableCell>
                  <TableCell>スライダー</TableCell>
                  <TableCell>野球選手</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>豊臣秀吉</TableCell>
                  <TableCell>サッカー</TableCell>
                  <TableCell>リフティング</TableCell>
                  <TableCell>野球選手</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>徳川家康</TableCell>
                  <TableCell>バスケ</TableCell>
                  <TableCell>アリウープ</TableCell>
                  <TableCell>エンジニア</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </section>

    </>
  );
}
`}
            </pre>
          </div>
        </>}
        {!showAnswer && <>
          <button className="answer_btn" onClick={() => {
            setShowAnswer(true)
          }}>回答を表示</button>
        </>}
      </section>
    </>
  );
}